const config = {
  appName: "fairdeal-wms",
  apiUrl: "https://prod.internals.api.fairdeal.market/api/",
  socketUrl: "https://prod.nodeapi.fairdeal.market/",
  // apiUrl: "http://localhost:3888/api/",
  // socketUrl: "http://localhost:3888/",
  // apiDevUrl: process.env.REACT_APP_API_DEV_URL,
  // socketDevUrl: process.env.REACT_APP_SOCKET_DEV_URL,
  // rp_key: process.env.REACT_APP_RP_KEY
};

export default config;
